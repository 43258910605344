import _ from 'lodash'
import { SiteMapSymbol } from '../symbols'
import { withDependencies } from '@wix/thunderbolt-ioc'
import { TpaHandlerProvider } from '@wix/thunderbolt-symbols'
import { ISiteMap, SitePagesResponse } from '../types'

export const GetSiteMapHandler = withDependencies(
	[SiteMapSymbol],
	({ getSiteMap }: ISiteMap): TpaHandlerProvider => ({
		getTpaHandlers() {
			return {
				getSitePages(compId: string, { includePagesUrl }: { includePagesUrl: boolean }): SitePagesResponse {
					return getSiteMap().map((item) => ({
						hide: item.hidden,
						id: _.isString(item.pageId) && item.pageId.replace('#', ''),
						isHomepage: item.isHomePage || false,
						title: item.title,
						...(includePagesUrl && { url: item.url }),
					}))
				},
				getSiteMap,
			}
		},
	})
)
