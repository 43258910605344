import { named, optional, withDependencies } from '@wix/thunderbolt-ioc'
import { createLinkUtils } from '@wix/thunderbolt-commons'
import _ from 'lodash'
import { MasterPageFeatureConfigSymbol, SiteFeatureConfigSymbol } from '@wix/thunderbolt-symbols'
import { name as tpaCommonsName, TpaCommonsSiteConfig } from 'feature-tpa-commons'
import { name } from '../symbols'
import { IRoutingLinkUtilsAPI, RoutingLinkUtilsAPISymbol } from 'feature-router'
import { IPopupsLinkUtilsAPI, PopupsLinkUtilsAPISymbol } from 'feature-popups'
import { IMultilingualLinkUtilsAPI, MultilingualLinkUtilsAPISymbol } from 'feature-multilingual'
import { Link, TpaMasterPageConfig, SiteMapResponse, ISiteMap, SiteMapLink } from '../types'
import { LINK_TYPES } from './constants'

export const MULTI_SECTION_DELIMITER = '$TPA$'

export const LINK_PANEL_PROPS = {
	[LINK_TYPES.PAGE_LINK]: ['type', 'pageId'],
	[LINK_TYPES.EXTERNAL_LINK]: ['type', 'target', 'url'],
	[LINK_TYPES.ANCHOR_LINK]: ['type', 'anchorName', 'anchorDataId', 'pageId'],
	[LINK_TYPES.EMAIL_LINK]: ['type', 'recipient', 'subject'],
	[LINK_TYPES.PHONE_LINK]: ['type', 'phoneNumber'],
	[LINK_TYPES.WHATSAPP_LINK]: ['type', 'phoneNumber'],
	[LINK_TYPES.DOCUMENT_LINK]: ['type', 'docId', 'name'],
	[LINK_TYPES.DYNAMIC_PAGE_LINK]: ['type', 'routerId', 'innerRoute', 'anchorDataId'],
	[LINK_TYPES.ADDRESS_LINK]: ['type', 'address'],
}

function isPageMarkedAsHideFromMenu(
	appsClientSpecMapByApplicationId: TpaCommonsSiteConfig['appsClientSpecMapByApplicationId'],
	linkObject: Link
) {
	if (!linkObject) {
		return false
	}
	if (linkObject.type === LINK_TYPES.PAGE_LINK) {
		let tpaPageId = linkObject.pageId?.tpaPageId
		const applicationId = linkObject.pageId?.tpaApplicationId
		const appData = appsClientSpecMapByApplicationId[applicationId!]
		if (appData && tpaPageId) {
			if (_.includes(tpaPageId, MULTI_SECTION_DELIMITER)) {
				tpaPageId = tpaPageId.substr(0, tpaPageId.indexOf(MULTI_SECTION_DELIMITER))
			}
			const section = _.find(appData.widgets, (widget) => widget.appPage?.id === tpaPageId)
			return section?.appPage.hideFromMenu
		}
	}
	return false
}

function getSitePagesFromMenuItems({ menuItems, mainPageId, baseUrl, linkUtils }: any): Array<SiteMapLink> {
	return _.map(menuItems, (item) => {
		const result = getEnhancedPageInfo(item, mainPageId, baseUrl, linkUtils)
		if (result.type === LINK_TYPES.PAGE_LINK || result.type === LINK_TYPES.MENU_HEADER) {
			const subPages = _.map(item.items, (subItem) =>
				getEnhancedPageInfo(subItem, mainPageId, baseUrl, linkUtils)
			)
			if (_.size(subPages) > 0) {
				_.assign(result, {
					subPages,
				})
			}
		}
		return result
	})
}

function getRelevantLinkDataFromMenuItem(menuItem: any): SiteMapLink {
	const link = _.pick(menuItem.link, LINK_PANEL_PROPS[menuItem.link.type])
	if (_.isObject(link.pageId)) {
		link.pageId = `#${_.get(link, 'pageId.id')}`
	}
	if (link.anchorDataId) {
		const anchorDataId = _.get(link, 'anchorDataId.id', link.anchorDataId)
		if (anchorDataId === 'SCROLL_TO_TOP' || anchorDataId === 'SCROLL_TO_BOTTOM') {
			link.anchorDataId = anchorDataId
		} else {
			link.anchorDataId = `#${anchorDataId}`
		}
	}
	return link
}

function getEnhancedPageInfo(menuItem: any, mainPageId: string, baseUrl: string, linkUtils: any): SiteMapLink {
	const title = menuItem.label || ''
	const hidden = !menuItem.isVisible || false
	const linkData = menuItem.link ? getRelevantLinkDataFromMenuItem(menuItem) : {}
	const link = _.merge(linkData, {
		title,
		hidden,
	})

	if (!link.type) {
		return {
			type: LINK_TYPES.MENU_HEADER,
			hidden,
			title,
			subPages: link.subPages,
		}
	}

	switch (link.type) {
		default:
		case LINK_TYPES.PAGE_LINK:
			_.merge(link, getExtraPageInfo(menuItem, mainPageId, baseUrl))
			break
		case LINK_TYPES.ANCHOR_LINK:
			_.merge(link, getExtraPageInfo(menuItem, mainPageId, baseUrl))
			break
		case LINK_TYPES.DOCUMENT_LINK:
			const linkUrl = linkUtils.getLinkUrlFromDataItem(link)
			const linkProps = linkUtils.getLinkProps(linkUrl)
			link.url = linkProps.href
			break
	}

	return link
}

function getExtraPageInfo(pageData: any, mainPageId: string, baseUrl: string) {
	const pageUriSEO = pageData.link?.pageId?.pageUriSEO
	const info = {
		isHomePage: pageData.link?.pageId?.id === mainPageId,
	}
	if (pageUriSEO) {
		_.assign(info, { url: baseUrl + '/' + pageUriSEO })
	}
	return info
}

export const SiteMap = withDependencies(
	[
		named(SiteFeatureConfigSymbol, tpaCommonsName),
		named(MasterPageFeatureConfigSymbol, name),
		RoutingLinkUtilsAPISymbol,
		optional(PopupsLinkUtilsAPISymbol),
		optional(MultilingualLinkUtilsAPISymbol),
	],
	(
		tpaCommonsSiteConfig: TpaCommonsSiteConfig,
		tpaMasterPageConfig: TpaMasterPageConfig,
		routingLinkUtilsAPI: IRoutingLinkUtilsAPI,
		popupsLinkUtilsAPI: IPopupsLinkUtilsAPI,
		multilingualLinkUtilsAPI?: IMultilingualLinkUtilsAPI
	): ISiteMap => ({
		getSiteMap(): SiteMapResponse {
			const {
				externalBaseUrl,
				appsClientSpecMapByApplicationId,
				metaSiteId,
				isPremiumDomain,
				userFileDomainUrl,
				routersConfig,
				isMobileView,
			} = tpaCommonsSiteConfig
			const { menuData } = tpaMasterPageConfig
			const linkUtilsRoutingInfo = routingLinkUtilsAPI.getLinkUtilsRoutingInfo()
			const linkUtils = createLinkUtils({
				routingInfo: linkUtilsRoutingInfo,
				metaSiteId,
				userFileDomainUrl,
				isPremiumDomain,
				routersConfig,
				popupPages: popupsLinkUtilsAPI?.getPopupPages(),
				multilingualInfo: multilingualLinkUtilsAPI?.getMultilingualInfo(),
				isMobileView,
			})

			return getSitePagesFromMenuItems({
				menuItems: _.filter(
					menuData.items,
					(item: any) => !isPageMarkedAsHideFromMenu(appsClientSpecMapByApplicationId, item.link)
				),
				mainPageId: linkUtilsRoutingInfo.mainPageId,
				baseUrl: externalBaseUrl,
				linkUtils,
			})
		},
	})
)
