import { withDependencies } from '@wix/thunderbolt-ioc'
import { TpaHandlerProvider } from '@wix/thunderbolt-symbols'

const noop = () => {}

export const EditorHandlers = withDependencies(
	[],
	(): TpaHandlerProvider => ({
		getTpaHandlers() {
			return {
				// TODO implement when we're in the editor
				setColorParam: noop,
				setStyleParam: noop,
				resizeComponent: noop,
			}
		},
	})
)
